/*
Copyright (C) 2019 LifeUp OÜ - All Rights Reserved
Unauthorized copying of this file, via any medium is strictly prohibited
Proprietary and confidential
 */
import classNames from 'classnames'
import React from 'react'
import { connect } from 'react-redux'
import { isEmpty, get, omit, isNumber, toNumber } from 'lodash-es'
import * as actions from '../../../store/person/actions'
import * as rest from '../../../utils/rest'
import { parseParams } from '../../../utils/strings'
import { setStorageEntry, getStorageEntry, removeStorageEntries } from '../../../utils/sessionStorage'
import { Page } from '../../../components/Page/Page'
import { PersonGeneralForm } from './components/PersonGeneralForm'
import { Spinner } from '../../../components/Spinner/Spinner'
import { FormRenderer } from '../../../components/FormRenderer/FormRenderer'
import { RouteLeavingGuard } from '../../../components/RouteLeavingGuard/RouteLeavingGuard'

class PersonEdit extends React.Component {
  state = {
    allTags: [],
    personFormValues: {},
    additionalForm: {},
    additionalFormSubmission: { data: {} },
    additionalFormValues: {},
    showAdditionalForm: false,
    showPageLeaveConfirm: true,
    loading: false
  }

  storageKeyForFormValues = 'person-form-values'
  submitButtonId = 'person-submit-button'
  validateAdditionalFormButtonId = 'custom-button-validate-person-form'
  formioRef = React.createRef()

  async componentDidMount() {
    const {
      readPerson,
      match: { params },
      location
    } = this.props
    const { showAdditionalForm } = parseParams(location.search)

    if (this.isEditMode) {
      readPerson(params.id)
    }

    this.setState({ loading: true })
    const allTags = await rest.get('/lifeup/internal/core/tag/page?sort=name&itemsPerPage=0')
    const additionalForm = await rest.get('/lifeup/internal/core/system/form/page?name=eq:crm_person_custom_form')

    if (additionalForm && !isEmpty(additionalForm.data)) {
      this.setState({ additionalForm: additionalForm.data[0], showAdditionalForm: !!showAdditionalForm })
    }

    if (allTags) {
      this.setState({ allTags: allTags.data })
    }

    this.setState({ loading: false })
  }

  componentWillUnmount() {
    const { clearCurrentPerson } = this.props

    clearCurrentPerson()
    removeStorageEntries(new RegExp(this.storageKeyForFormValues))
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { person, t, ui } = this.props

    if (!prevProps.person.error && person.error) {
      ui.showAlert({
        type: 'error',
        message: person.error.name === 'DuplicateKeyException' ? t('person.error.duplicate.email') : t('person.error.common.create')
      })
    }
  }

  get isEditMode() {
    const {
      match: { params }
    } = this.props

    return !!params.id
  }

  get definition() {
    const { additionalForm } = this.state

    return additionalForm.definition ? JSON.parse(additionalForm.definition) : null
  }

  get submission() {
    const { person } = this.props

    return {
      data: {
        ...get(person, 'currentPerson.externalData', {})
      }
    }
  }

  get initialForm() {
    const {
      authInfo,
      person: { currentPerson }
    } = this.props
    const { allTags } = this.state
    const savedFormValues = getStorageEntry(authInfo, this.storageKeyForFormValues) || {}

    const formValues = this.isEditMode
      ? currentPerson
      : {
          ...savedFormValues,
          ...(!isEmpty(savedFormValues.tags)
            ? { tags: savedFormValues.tags.map(tag => allTags.find(t => t.name === tag.name || t.id === tag.id)).filter(t => !!t) }
            : {})
        }

    return {
      ...formValues,
      ...(formValues.personalNumber
        ? {
            countryCode: !isNumber(formValues.personalNumber.substr(0, 2)) ? formValues.personalNumber.substr(0, 2) : '',
            personalCode: get(formValues, 'personalNumber', '').replace(/^\D+/g, '')
          }
        : {}),
      ...{ tags: !isEmpty(formValues.tags) ? formValues.tags.map(tag => tag.name) : [] }
    }
  }

  render() {
    const { common, person, t, i18n, history, location } = this.props
    const { showAdditionalForm, personFormValues, showPageLeaveConfirm } = this.state
    const loading = this.state.loading || person.loading

    return (
      <>
        <RouteLeavingGuard
          t={t}
          when={showPageLeaveConfirm}
          message={t('customer.create.leave.page')}
          navigate={({ pathname, search }) => history.push({ pathname, search })}
          shouldBlockNavigation={l => l.pathname !== location.pathname}
        />
        <Page className={classNames('private-person', 'edit', { create: !this.isEditMode })} footerControls={this.getFooterControls()}>
          <Spinner loading={loading} />
          <h3 className="heading">{t(`page.title.${this.isEditMode ? 'edit' : 'creating'}`, { name: t('common.person') })}</h3>
          {!loading && (
            <div className="content-wrapper">
              {showAdditionalForm ? (
                <FormRenderer
                  i18n={i18n}
                  language={i18n.language}
                  form={this.definition}
                  submission={this.submission}
                  onChange={this.handleAdditionalFormChange}
                  validateButtonId={this.validateAdditionalFormButtonId}
                  formioRef={this.formioRef}
                />
              ) : (
                <PersonGeneralForm
                  data={{
                    ...this.initialForm,
                    ...personFormValues
                  }}
                  common={common}
                  onSubmit={this.onSubmit}
                  submitButtonId={this.submitButtonId}
                  t={t}
                />
              )}
            </div>
          )}
        </Page>
      </>
    )
  }

  handleAdditionalFormChange = ({ data }) => {
    this.setState(state => ({ ...state, additionalFormValues: data }))
  }

  onSubmit = (personData = {}) => {
    const {
      t,
      ui,
      authInfo,
      createPerson,
      updatePerson,
      history,
      match: { params }
    } = this.props
    const { showAdditionalForm, additionalFormValues, personFormValues } = this.state
    const hasAdditionalForm = !isEmpty(get(this.definition, 'components'))

    const data = isEmpty(personData) && isEmpty(personFormValues) ? { ...this.initialForm } : { ...personFormValues, ...personData }
    const personalCode = get(data, 'personalCode', '')
    const countryCode = get(data, 'countryCode', '')
    const formValues = {
      ...omit(data, ['tags', 'countryCode', 'personalCode']),
      personalNumber: personalCode.includes(countryCode) ? personalCode : `${countryCode}${personalCode}`,
      tags: isEmpty(data.tags) ? [] : data.tags.map(tag => (isNaN(toNumber(tag)) ? { name: tag } : { id: toNumber(tag) })),
      externalData: {
        ...omit(additionalFormValues, 'submit')
      }
    }

    this.setState({ personFormValues: personData })

    if (showAdditionalForm || !hasAdditionalForm) {
      const redirectCallback = id => {
        ui.showAlert({ message: t(`person.success.${this.isEditMode ? 'update' : 'create'}`) })
        this.setState({ showPageLeaveConfirm: false }, () => history.push(`/person/${id}`))
      }
      const onSubmit = params.id ? updatePerson : createPerson

      return onSubmit(formValues, redirectCallback)
    }

    if (hasAdditionalForm) {
      this.setState({ showAdditionalForm: true })

      !this.isEditMode && setStorageEntry(authInfo, this.storageKeyForFormValues, formValues)
    }
  }

  getFooterControls = () => {
    const { history, t } = this.props
    const { showAdditionalForm } = this.state
    const hasAdditionalForm = get(this.definition, 'components.length', 0) !== 0

    return [
      {
        label: t('button.cancel'),
        onClick: history.goBack,
        outlineColor: 'none'
      },
      ...(showAdditionalForm
        ? [
            {
              label: t('button.back'),
              onClick: () => this.setState({ showAdditionalForm: false }),
              outlineColor: 'gray'
            },
            {
              label: t('button.save'),
              labelForId: this.validateAdditionalFormButtonId,
              onClick: () => setTimeout(() => this.onSubmit(), 0),
              outlineColor: 'gray',
              disabled: this.formioRef.current === null
            }
          ]
        : [
            {
              label: t(hasAdditionalForm ? 'button.next' : 'button.save'),
              labelForId: this.submitButtonId,
              outlineColor: 'gray'
            }
          ])
    ]
  }
}

const mapStateToProps = ({ person, common }) => ({ person, common })
const mapDispatchToProps = dispatch => ({
  createPerson: (data, redirectCb) => dispatch(actions.createPersonRequest({ data, redirectCb })),
  readPerson: id => dispatch(actions.readPersonRequest(id)),
  updatePerson: (data, redirectCb) => dispatch(actions.updatePersonRequest({ data, redirectCb })),
  clearCurrentPerson: () => dispatch(actions.personClear())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonEdit)
